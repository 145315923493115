import * as React from 'react';

// @mui
import { Container } from "@mui/material";

// hooks
import useSettings from '../../hooks/useSettings';
// components
import DashboardLayout from '../../layouts/dashboard';
import Page from '../../components/Page';

import { ValidatorDetail } from "../../sections";
import {AddressDisplay} from "../../utils/formatSubstrate";

// ----------------------------------------------------------------------

export default function ValidatorDetailPage({validatorId}) {
    const { themeStretch } = useSettings();

    const validatorLabel=AddressDisplay(validatorId);

    return (
        <DashboardLayout>
            <Page title={`Validator Detail: ${validatorLabel}`}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <ValidatorDetail validatorId={validatorId}/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
